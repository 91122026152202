<template>
  <button
    class="btn btn-secondary btn-sm"
    :title="$t(`imageUpload.uploadImage`)"
    v-on:click="$refs.file.click()"
    :class="{ 'loading-spin spin-dark': loadBtn }"
    :disabled="loadBtn"
  >
    <span>
      <BaseIcon name="gallery" color="var(--black)" />
    </span>
  </button>
  <input type="file" ref="file" v-on:change="upload" class="visually-hidden" />
</template>

<script>
import http from "../../modules/http";
import BaseIcon from "../icons/BaseIcon";
import tenant from "../../modules/tenant";

export default {
  name: "ImageUpload",
  components: { BaseIcon },
  props: {
    id: Number,
    type: String,
    name: String,
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  emits: ["update"],
  data: function () {
    return {
      loadBtn: false,
    };
  },
  methods: {
    upload() {
      this.loadBtn = true;
      let file = this.$refs.file.files[0];

      const request = new XMLHttpRequest();
      const _this = this;

      let send = {
        type: this.type,
        id: this.id,
        images: [
          this.name ? this.name + "." + file.name.split(".").pop() : file.name,
        ],
      };
      if (_this.isVariant) {
        send.is_variant = true;
      }

      http.fetch("/files/upload", send).then((data) => {
        const formData = new FormData();
        formData.append(
          "image",
          file,
          this.name ? this.name + "." + file.name.split(".").pop() : file.name
        );
        formData.append("token", data.token);
        formData.append("album", this.id);
        formData.append("category", this.type);

        request.open(
          "POST",
          process.env.VUE_APP_MEDIA_API +
            "/" +
            tenant.getTenantName() +
            "/images/upload"
        );

        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            let data2 = JSON.parse(request.responseText);
            _this.loadBtn = false;
            _this.$emit("update", data2.operation.images[0]);
          } else {
            console.log("oh no");
            _this.loadBtn = false;
          }
        };

        request.send(formData);
      });
    },
  },
};
</script>
