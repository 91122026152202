<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <h4 class="mb-2">
{{ $t("lineOptions.page") }}
</h4>
        <div class="items" v-if="paths === undefined">
          <Skeletor v-for="i in 5" height="30" class="mb-1 btn" :key="i" />
        </div>
        <div class="items" v-else>
          <button
            class="btn w-100 text-start"
            v-for="(path, i) in paths"
            :class="selectedPath?.id === path.id ? 'btn-primary' : 'btn-simple'"
            :key="path.id"
            v-on:click="
              selectedPath = path;
              selectRows(i);
            "
          >
            {{ path.name }}
          </button>
        </div>
        <h4 class="my-2">
{{ $t("lineOptions.lineOption") }}
</h4>
        <div class="items" v-if="lineOptions === undefined">
          <Skeletor v-for="i in 5" height="30" class="mb-1 btn" :key="i" />
        </div>
        <div class="items" v-else>
          <button
            class="btn w-100 text-start"
            v-for="(lineOption, i) in lineOptions"
            :class="
              selectedLineOption?.id === lineOption.id
                ? 'btn-primary'
                : 'btn-simple'
            "
            :key="lineOption.id"
            v-on:click="changeLineOption(i)"
          >
            {{ lineOption.name }}
          </button>
        </div>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10" v-if="selectedLineOption">
      <div class="row">
        <div class="col">
          <h2>
{{ $t("lineOptions.lineOptionSettings") }}
</h2>
          <h4>
            <b>{{ selectedPath.name }} / {{ selectedLineOption.name }}</b>
          </h4>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-link text-gray-dark"
            v-if="selectedLineOption.deleteable !== 0"
            v-on:click="deleteLineOption(selectedLineOption)"
          >
            <BaseIcon name="trash" />
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5>{{ $t("lineOptions.columns") }}</h5>
          <draggable
            v-if="rows !== undefined"
            class="dragArea"
            tag="ul"
            :list="rows"
            :group="{ name: 'g1' }"
            item-key="row_key"
            v-on:end="moveEvent"
            v-bind="dragOptions"
          >
            <template #item="{ element }">
              <li>
                <div
                  class="cstm-check dropdown-item"
                  v-if="element.could_hidden !== false"
                >
                  <div class="row">
                    <span
                      class="text-black mx-2 regular-14 col-9 col-lg-5 mt-2"
                      >{{ element.row_name }}</span
                    >
                    <div class="col-2">
                      <BaseIcon name="order" />
                    </div>
                    <div class="col-2" v-if="element.is_custom">
                      <button
                        class="btn btn-link text-gray-dark"
                        v-on:click="customRowModal(element.id)"
                      >
                        <base-icon name="edit" />
                      </button>
                    </div>
                    <div
                      class="col-2"
                      v-if="element.is_characterics && canCharacteristics"
                    >
                      <button
                        class="btn btn-link text-gray-dark"
                        v-on:click="characteristicsModal(element.id)"
                      >
                        <base-icon name="edit" />
                      </button>
                    </div>
                    <label class="col-auto">
                      <input
                        type="checkbox"
                        :checked="
                          selectedLineOption.rows.indexOf(element?.row_key) > -1
                        "
                        v-on:change="
                          change(element, $event.currentTarget.checked)
                        "
                      />
                      <span class="checkmark select mx-2"></span>
                    </label>
                  </div>
                </div>
                <label class="dropdown-item" v-else>
                  <span class="text-black mx-2 ms-4 regular-14">{{
                    element.row_name
                  }}</span>
                </label>
              </li>
            </template>
          </draggable>
        </div>
        <aside class="col-md-5 col-lg-3">
          <section>
            <h5>{{ $t("lineOptions.visibleFor") }}</h5>
            <label class="cstm-check dropdown-item">
              <span class="text-black mx-2 regular-14">{{ $t("lineOptions.justMe") }}</span>
              <input
                type="checkbox"
                v-model="visible"
                :value="-1"
                v-on:change="changeVisible(-1)"
              /><span class="checkmark mx-2"></span>
            </label>
            <label class="cstm-check dropdown-item">
              <span class="text-black mx-2 regular-14">{{ $t("lineOptions.allGroups") }}</span>
              <input
                type="checkbox"
                v-model="visible"
                :value="0"
                v-on:change="changeVisible(0)"
              /><span class="checkmark mx-2"></span>
            </label>
            <label
              class="cstm-check dropdown-item"
              v-for="role in roles"
              :key="role.id"
            >
              <span class="text-black mx-2 regular-14">{{ role.name }}</span>
              <input
                type="checkbox"
                v-model="visible"
                :value="role.id"
                v-on:change="
                  changeVisible(role.id, !$event.currentTarget.checked)
                "
              /><span class="checkmark mx-2"></span>
            </label>
          </section>
          <section v-if="canCustomRows">
            <h5>{{ $t("customRows.customRows") }}</h5>
            <button
              class="btn btn-secondary mt-3"
              v-on:click="customRowModal(null)"
            >
              {{ $t("lineOptions.addNewRow") }}
            </button>
          </section>
          <section v-if="canCharacteristics">
            <h5>{{ $t("lineOptions.characteristics") }}</h5>
            <button
              class="btn btn-secondary mt-3"
              v-on:click="characteristicsModal()"
            >
              {{ $t("lineOptions.addNewCharacteristics") }}
            </button>
          </section>
        </aside>
      </div>
    </div>
  </div>
  <CustomRowModal
    ref="editCustomRowModal"
    v-model="selectedustomRow"
    v-on:newItem="addCustomRow"
    :type="selectedPath?.id"
  />
  <CharacteristicsModal
    ref="addCharactericsModal"
    :edit-characteristics="editCharacteristics"
    :type="characteristicsType"
    v-on:change="characteristicsChange"
    v-on:newItem="addCustomRow"
  />
</template>

<script>
import http from "../../modules/http";
import draggable from "vuedraggable";
import { useStore } from "vuex";
import BaseIcon from "@/components/icons/BaseIcon";
import CustomRowModal from "../modals/CustomRowModal";
import CharacteristicsModal from "../modals/CharacteristicsModal";

export default {
  name: "LineOptions",
  components: {
    CharacteristicsModal,
    CustomRowModal,
    BaseIcon,
    draggable,
  },
  data() {
    const store = useStore();
    return {
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      store: store,
      roles: [],
      paths: undefined,
      selectedPath: undefined,
      selectedustomRow: undefined,
      editCharacteristics: undefined,
      lineOptions: undefined,
      selectedLineOption: undefined,
      rows: undefined,
      visible: [],
    };
  },
  computed: {
    languages() {
      return this.store.state.languages;
    },
    canCustomRows() {
      return (
        this.selectedPath?.id === "customers" ||
        this.selectedPath?.id === "teams" ||
        this.selectedPath?.id === "partners"
      );
    },
    canCharacteristics() {
      return this.selectedPath?.id.indexOf("products::") > -1;
    },
    characteristicsType() {
      return this.selectedPath?.id.replace("products::", "").replace("::*", "");
    },
  },
  mounted() {
    http.fetch("/line-options/paths").then((data) => {
      this.paths = data;
      if (data.length > 0) {
        this.selectedPath = this.paths[0];
        this.selectRows(0);
      }
    });
    http
      .fetch("/users/roles")
      .then((data) => {
        this.roles = data;
      })
      .catch(() => {
        this.roles = [];
      });
  },
  methods: {
    deleteLineOption(option) {
      http.fetch("/line-options/delete", { id: option.id }, true).then(() => {
        let i = this.lineOptions?.indexOf(option);
        if (i > -1) {
          this.lineOptions.splice(i, 1);
          this.selectedLineOption = undefined;
        }
      });
    },
    changeLineOption(i) {
      this.selectedLineOption = this.lineOptions[i];
      if (this.selectedLineOption.visibility === 2) {
        this.visible = this.selectedLineOption.line_option_groups;
      } else if (this.selectedLineOption.visibility === 1) {
        this.visible = [-1];
      } else {
        this.visible = [0];
      }
    },
    change(row, checked) {
      let data = {
        id: row.id,
        line_option: this.selectedLineOption.id,
      };
      if (row.is_characterics) {
        data.is_characterics = true;
      }
      if (row.is_custom) {
        data.is_custom = true;
      }
      if (row.is_external) {
        data.is_external = true;
      }
      if (row.language !== undefined) {
        data.language = row.language;
      }
      if (row.currency !== undefined) {
        data.currency = row.currency;
      }
      if (row.warehouse !== undefined) {
        data.warehouse = row.warehouse;
      }
      if (row.category !== undefined) {
        data.category = row.category;
      }
      if (checked) {
        http.fetch("/line-options/add-row", data, true);
        this.selectedLineOption.rows.push(row.row_key);
      } else {
        http.fetch("/line-options/remove-row", data, true);
        this.selectedLineOption.rows.splice(
          this.selectedLineOption.rows?.indexOf(row.row_key),
          1
        );
      }
    },
    selectRows(i) {
      http.fetch("/line-options/" + this.paths[i].id).then((data2) => {
        this.rows = data2.rows;

        this.lineOptions = data2.line_options;
        if (this.lineOptions.length > 0) {
          this.changeLineOption(0);
        }
      });
    },
    changeVisible(visible, hide = false) {
      let data = { id: this.selectedLineOption.id };
      if (visible > 0) {
        data.visibility = 2;
        data.group = visible;
        if (hide) {
          data.hide = true;
        }
        this.visible = this.visible.filter((e) => e > 0);
        this.selectedLineOption.visibility = 2;
        this.selectedLineOption.group = visible;
      } else if (visible === 0) {
        this.visible = [0];
        data.visibility = 0;
        this.selectedLineOption.visibility = 0;
      } else if (visible === -1) {
        this.visible = [-1];
        data.visibility = 1;
        this.selectedLineOption.visibility = 1;
      }
      http.fetch("/line-options/edit", data, true);
    },

    moveEvent() {
      let data = [];
      for (const row of this.rows) {
        let rowd = {
          id: row.id,
        };

        if (row.is_characterics) {
          rowd.is_characterics = true;
        }
        if (row.is_custom) {
          rowd.is_custom = true;
        }
        if (row.is_external) {
          rowd.is_external = true;
        }
        data.push(rowd);
      }

      http.fetch(
        "/line-options/" + this.selectedPath.id + "/order",
        { rows: data },
        true
      );
    },

    customRowModal(id = null) {
      if (id !== null) {
        http.fetch("/custom-rows?path=" + this.selectedPath.id).then((data) => {
          this.selectedustomRow = data.find((e) => e.id === id);
          this.$refs.editCustomRowModal.showModal();
        });
      } else {
        this.selectedustomRow = undefined;
        this.$refs.editCustomRowModal.showModal();
      }
    },
    addCustomRow() {
      http.fetch("/line-options/" + this.selectedPath.id).then((data2) => {
        this.rows = data2.rows;
      });
    },
    characteristicsModal(id = false) {
      this.editCharacteristics = id;
      this.$refs.addCharactericsModal.showModal();
    },
    characteristicsChange(v) {
      this.rows[this.v.findIndex((e) => e.id === v.id)] = v.name;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
.dragArea {
  min-height: 2px;
}
</style>
