<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t(`customRowModal.customRowTo`, {expr: modelValue !== undefined ? "Edit" : "Add", type: type}) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="text-start m-2 custom-input col-12">
              <div class="row">
                <div class="col-12 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{ $t("customRowModal.rowName") }}</label>
                  <input class="form-control" v-model="addRow.name" />
                </div>
                <div class="col-12 mb-3">
                  <h5 class="mb-2">{{ $t("catalogImport.type") }}</h5>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{ $t("customRowModal.text") }}</span>
                    <input type="radio" v-model="addRow.type" :value="5" /><span
                      class="checkmark mx-2"
                    ></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{ $t("characteristicsModal.number") }}</span>
                    <input
                      type="radio"
                      v-model="addRow.type"
                      :value="1"
                    /><span /><span class="checkmark mx-2"></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14"
                      >{{ $t("characteristicsModal.fractionalNumber") }}</span
                    >
                    <input type="radio" v-model="addRow.type" :value="2" /><span
                      class="checkmark mx-2"
                    ></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">True/False</span>
                    <input type="radio" v-model="addRow.type" :value="3" /><span
                      class="checkmark mx-2"
                    ></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{ $t("characteristicsModal.multipleValueEnum") }}</span>
                    <input type="radio" v-model="addRow.type" :value="4" /><span
                      class="checkmark mx-2"
                    ></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{ $t("characteristicsModal.date") }}</span>
                    <input type="radio" v-model="addRow.type" :value="7" /><span
                      class="checkmark mx-2"
                  ></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{ $t("finance.email") }}</span>
                    <input type="radio" v-model="addRow.type" :value="10" /><span
                      class="checkmark mx-2"
                  ></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{ $t("addNewContactsModal.phone") }}</span>
                    <input type="radio" v-model="addRow.type" :value="11" /><span
                      class="checkmark mx-2"
                  ></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{ $t("customRowModal.enumWithText") }}</span>
                    <input type="radio" v-model="addRow.type" :value="8" /><span
                      class="checkmark mx-2"
                  ></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{ $t("customRowModal.files") }}</span>
                    <input type="radio" v-model="addRow.type" :value="13" /><span
                      class="checkmark mx-2"
                  ></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{ $t("customRowModal.filesWithLabel") }}</span>
                    <input type="radio" v-model="addRow.type" :value="12" /><span
                      class="checkmark mx-2"
                  ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="modelValue !== undefined"
            type="button"
            class="btn btn-primary"
            v-on:click="save"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("finance.save") }}
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            v-on:click="add"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("customRowModal.addRow") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";

const bootstrap = require("bootstrap");

export default {
  name: "CustomRowModal",
  props: {
    modelValue: Object,
    type: String,
  },
  data() {
    return {
      myModal: undefined,
      loadAddBtn: false,
      addRow: {
        path: this.type,
      },
    };
  },
  watch: {
    modelValue() {
      if (this.modelValue !== undefined) {
        this.addRow.name = this.modelValue.name;
        this.addRow.type = this.modelValue.type;
      } else {
        this.addRow = { path: this.type };
      }
    },
  },
  emits: ["update:modelValue", "newItem"],
  methods: {
    showModal() {
      this.myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      this.myModal.show();
    },
    save() {
      this.addRow.path = this.type;
      http
        .fetch("/custom-rows/"+this.modelValue.id, this.addRow, true)
        .then(() => {
          this.$emit("update:modelValue", {
            id: this.modelValue.id,
            name: this.addRow.name,
            type: this.addRow.type,
          });
          this.myModal.hide();
          this.loadAddBtn = false;
          this.addRow = { path: this.type };
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    add() {
      this.loadAddBtn = true;
      this.addRow.path = this.type;

      http
        .fetch("/custom-rows", this.addRow, true)
        .then((data) => {
          this.$emit("newItem", data);
          this.myModal.hide();
          this.loadAddBtn = false;
          this.addRow = { path: this.type };
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
  },
};
</script>
