<template>
  <div
    ref="addCharactericsModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      :class="{ 'modal-lg': editCharacteristics }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{
              $t(`characteristicsModal.characterics2`, {
                expr: editCharacteristics ? "Edit" : "Add",
              })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row row-tabs">
            <div
              class="col-auto p-0 m-0"
              v-for="l in activeLanguages"
              :key="l.code"
            >
              <button
                class="btn btn-tab"
                :class="{ active: l.code === selectedLanguage }"
                v-on:click="selectedLanguage = l.code"
              >
                <span class="semibold-14">{{ l.name }}</span>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-3 mt-2" v-if="editCharacteristics">
              <button
                class="btn w-100 d-block text-start"
                v-on:click="editCharacteristicsValues = false"
                :class="{
                  'btn-primary': !editCharacteristicsValues,
                  'btn-simple': editCharacteristicsValues,
                }"
              >
                {{ $t("characteristicsModal.characterics") }}
              </button>
              <button
                class="btn w-100 d-block text-start"
                v-on:click="editCharacteristicsValues = true"
                :class="{
                  'btn-primary': editCharacteristicsValues,
                  'btn-simple': !editCharacteristicsValues,
                }"
              >
                {{ $t("characteristicsModal.values") }}
              </button>
            </div>
            <div
              class="text-start m-2 custom-input"
              v-if="!editCharacteristicsValues"
              :class="editCharacteristics ? 'col-8' : 'col-12'"
            >
              <div class="row">
                <div class="col-12 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("characteristicsModal.charactericsName")
                  }}</label>
                  <input
                    class="form-control"
                    v-model="addCharacterics.name[selectedLanguage]"
                  />
                </div>
                <div class="col-12 mb-3">
                  <h5 class="mb-2">
                    {{ $t("characteristicsModal.required") }}
                  </h5>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{
                      $t("characteristicsModal.required")
                    }}</span>
                    <input
                      type="checkbox"
                      v-model="addCharacterics.required"
                      :value="true"
                    /><span class="checkmark mx-2"></span>
                  </label>
                </div>
                <div class="col-12 mb-3">
                  <h5 class="mb-2">{{ $t("catalogImport.type") }}</h5>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{
                      $t("characteristicsModal.multipleValueEnum")
                    }}</span>
                    <input
                      type="radio"
                      v-model="addCharacterics.type"
                      :value="4"
                    /><span class="checkmark mx-2"></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{
                      $t("characteristicsModal.number")
                    }}</span>
                    <input
                      type="radio"
                      v-model="addCharacterics.type"
                      :value="1"
                    /><span /><span class="checkmark mx-2"></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{
                      $t("characteristicsModal.fractionalNumber")
                    }}</span>
                    <input
                      type="radio"
                      v-model="addCharacterics.type"
                      :value="2"
                    /><span class="checkmark mx-2"></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">True/False</span>
                    <input
                      type="radio"
                      v-model="addCharacterics.type"
                      :value="3"
                    /><span class="checkmark mx-2"></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{
                      $t("characteristicsModal.date")
                    }}</span>
                    <input
                      type="radio"
                      v-model="addCharacterics.type"
                      :value="7"
                    /><span class="checkmark mx-2"></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{
                      $t("characteristicsModal.color")
                    }}</span>
                    <input
                      type="radio"
                      v-model="addCharacterics.type"
                      :value="6"
                    /><span class="checkmark mx-2"></span>
                  </label>
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{
                      $t("characteristicsModal.file")
                    }}</span>
                    <input
                      type="radio"
                      v-model="addCharacterics.type"
                      :value="13"
                    /><span class="checkmark mx-2"></span>
                  </label>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("characteristicsModal.schemaType") }}
                  </label>
                  <v-select
                    v-model="addCharacterics.schema_type"
                    :options="
                      schemas.filter((e) => e.type === addCharacterics.type)
                    "
                    label="name"
                    :placeholder="$t(`characteristicsModal.notASpecificType`)"
                    :reduce="(e) => e.id"
                  />
                </div>
                <div
                  class="col-6 whitebg-input"
                  v-if="
                    addCharacterics.type === 1 || addCharacterics.type === 2
                  "
                >
                  <label class="form-label bold-12 text-gray">
                    {{ $t("characteristicsModal.valuePrefix") }}
                  </label>
                  <input
                    class="form-control"
                    v-model="addCharacterics.prefix[selectedLanguage]"
                  />
                </div>
                <div
                  class="col-6 whitebg-input"
                  v-if="
                    addCharacterics.type === 1 || addCharacterics.type === 2
                  "
                >
                  <label class="form-label bold-12 text-gray">{{
                    $t("characteristicsModal.valuePostfix")
                  }}</label>
                  <input
                    class="form-control"
                    v-model="addCharacterics.postfix[selectedLanguage]"
                  />
                </div>
              </div>
            </div>
            <div class="text-start col-8 m-2 custom-input" v-else>
              <div v-for="value in addCharacterics.values" :key="value.id">
                <div
                  class="row align-items-center"
                  v-if="
                    addCharacterics.type === 4 || addCharacterics.type === 6
                  "
                >
                  <div class="col">
                    <EditOnClick
                      v-model="value.value[selectedLanguage]"
                      v-on:update="
                        cValueUpdated(value.value, value.id, 'value')
                      "
                      :setWidth="false"
                      type="text"
                    />
                  </div>
                  <div
                    class="text-gray regular-14 col-6"
                    v-if="selectedLanguage !== 'en'"
                  >
                    {{ value.value["en"] }}
                  </div>
                  <div class="col-2" v-else>
                    <button
                      class="btn btn-transparent"
                      :title="$t(`characteristicsModal.deleteValue`)"
                      v-if="value.count < 1"
                      v-on:click="cValueDelete(value.id)"
                    >
                      <BaseIcon name="trash" />
                    </button>
                  </div>
                  <div
                    v-if="
                      addCharacterics.type === 6 && selectedLanguage === 'en'
                    "
                    class="col-4"
                  >
                    <EditOnClick
                      v-model="value.hex"
                      :setWidth="false"
                      type="colorPicker"
                      v-on:update="
                        (e) => cValueUpdated(e, value.id, 'custom_1')
                      "
                    />
                    <ImageUpload
                      :id="value.id"
                      type="color"
                      name="default"
                      v-on:update="
                        (e) => cValueUpdated(e.id, value.id, 'custom_2')
                      "
                    />
                    <img
                      :src="
                        mediaServer +
                        '/color/' +
                        value.id +
                        '/default/32-default.webp'
                      "
                      v-if="value.image"
                      alt=""
                      class="d-inline-block"
                    />
                  </div>
                </div>
                <span v-else>{{ value.value }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="editCharacteristics"
            type="button"
            class="btn btn-primary"
            v-on:click="saveCharacterics"
          >
            {{ $t("finance.save") }}
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            v-on:click="addNewCharacterics"
          >
            {{ $t("characteristicsModal.addCharacterics") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import EditOnClick from "../inputs/EditOnClick";
import ImageUpload from "../inputs/ImageUpload";
import { useStore } from "vuex";
import BaseIcon from "../icons/BaseIcon";

const bootstrap = require("bootstrap");

export default {
  name: "CharacteristicsModal",
  components: { BaseIcon, ImageUpload, EditOnClick },
  props: {
    editCharacteristics: Object,
    type: String,
  },
  data() {
    return {
      myModal: undefined,
      loadAddBtn: false,
      selectedLanguage: "en",
      editCharacteristicsValues: false,

      schemas: [
        { id: 1, name: "Brand", type: 4 },
        { id: 2, name: "Author", type: 4 },
        { id: 3, name: "Color", type: 6 },
        { id: 4, name: "Manufacturer", type: 4 },
        { id: 5, name: "Material", type: 4 },
        { id: 6, name: "Model", type: 4 },
        { id: 7, name: "Release date", type: 4 },
        { id: 8, name: "Size", type: 4 },
        { id: 9, name: "Pattern", type: 4 },
        { id: 10, name: "Adult", type: 3 },
        { id: 11, name: "Bundle", type: 3 },
        { id: 12, name: "Energy efficiency class", type: 4 },
        { id: 13, name: "Age group", type: 4 },
        { id: 14, name: "Gender", type: 4 },
        { id: 15, name: "Size system", type: 4 },
        { id: 16, name: "Weight (in gram)", type: 2 },
        { id: 17, name: "Box size (length x with x height)", type: 4 },
      ],

      addCharacterics: {
        type: 1,
        name: {},
        prefix: {},
        postfix: {},
        schema_type: null,
      },
    };
  },
  watch: {
    editCharacteristics() {
      this.characteristicsModal(this.editCharacteristics);
    },
  },
  computed: {
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    activeLanguages() {
      const store = useStore();
      return store.state.languages;
    },
  },
  emits: ["change", "newItem"],
  methods: {
    showModal() {
      if (this.addCharactericsModalw === undefined) {
        this.addCharactericsModalw = new bootstrap.Modal(
          this.$refs.addCharactericsModal,
          {
            backdrop: "static",
          }
        );
      }
      this.addCharactericsModalw.show();
    },
    saveCharacterics() {
      http
        .fetch(
          `/products/${this.type}/characteristics/edit`,
          this.addCharacterics,
          true
        )
        .then(() => {
          let ret = Object.assign({}, this.addCharacterics);
          ret.name = ret.name.en;
          this.$emit("change", ret);
          this.addCharactericsModalw.hide();
        });
    },
    addNewCharacterics() {
      http
        .fetch(
          `/products/${this.type}/characteristics/add`,
          this.addCharacterics,
          true
        )
        .then((data) => {
          this.addCharactericsModalw.hide();
          this.$emit("newItem", data);
        });
    },
    cValueUpdated(value, id, key) {
      let data = { id: id };
      data[key] = value;
      http.fetch(
        `/products/${this.type}/characteristics/edit-value`,
        data,
        true
      );
      if (key === "custom_2") {
        this.addCharacterics.values.find((e) => e.id === id).image = 1;
      }
    },
    cValueDelete(id) {
      let data = { id: id };
      http
        .fetch(
          `/products/${this.type}/characteristics/delete-value`,
          data,
          true
        )
        .then(() => {
          let e = this.addCharacterics.values.findIndex((e) => e.id === id);
          this.addCharacterics.values.splice(e, 1);
        });
    },
    characteristicsModal(id = false) {
      this.editCharacteristicsValues = false;

      if (id) {
        http
          .fetch(`/products/${this.type}/characteristics/${id}`)
          .then((data) => {
            this.addCharacterics = data;
          });
      } else {
        this.addCharacterics = {
          type: 1,
          name: {},
          prefix: {},
          postfix: {},
        };
      }
    },
  },
};
</script>
